import React from "react";
import Login from "../../Components/Admin/Login";

function AdminLogin() {


    return (
        <Login />
    )

}

export default AdminLogin;