import React from 'react';
import {Link} from 'react-router-dom';

function AccountsHeading() {

    return(
        <div className="event-heading">
            <h1 className="title noselect">Accounts</h1>
        </div>
    )
}

export default AccountsHeading;